<template>
	<div>
		<router-view />
	</div>
</template>

<script>
export default {
	name: "DemoLayout",
	data() {
		return {};
	},
};
</script>
